
import { Options } from "vue-class-component";
import ComponentConfigMixins from "../ComponentConfigMixins";

@Options({
    name: "flowable-common-form-components-multiple-select-config",
})
export default class MultipleSelectConfig extends ComponentConfigMixins {
    private dragOption: any = {
        animation: 300,
        sort: true,
    };

    public add() {
        this._value.options.push({ name: "", value: "" });
    }

    public del(index: any) {
        this._value.options.splice(index, 1);
    }
}
